@import url('https://fonts.googleapis.com/css?family=Nunito|Rubik&display=swap&subset=latin-ext');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

.home {
  width: calc(100vw - 17px);
}

$font-color: #f2880c;
$background-nav: #20232A;

nav {
  width: 100%;
  height: 80px;
  background-color: $background-nav;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}

.nav-burger {
  display: none;
}

.nav-logo {
  width: 50%;
  color: #fff;
  text-transform: uppercase;
  margin-left: 5px;
  letter-spacing: 5px;
  font-weight: 700;
}

.nav-logo p span:nth-child(even) {
  color: $font-color;
}

.nav-main {
  display: flex;
}

.nav-main ul {
  display: flex;

  li {
    color: #fff;
    list-style: none;
    text-transform: uppercase;
    padding-left: 40px;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
}

.nav-main ul li a:hover {
  color: $font-color;
  cursor: pointer;
  transition: 1s;
}

.nav-main img {
  width: 30px;
  padding-left: 10px;
}

.header-img {
  background-color: #000;
  height: 90vh;
  max-height: 1000px;
  background-image: url('../img/tiger.jpg');
  background-position: left -50px;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-mask-image: -webkit-gradient(linear, left top, right bottom,
          from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  background-attachment: fixed;
  animation-name: header-img;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.header-text {
  height: 10vh;
  background-color: $background-nav;
  color: white;
  text-align: center;
  line-height: 10vh;
  text-transform: uppercase;
  font-family: 'Nunito', sans-serif;
  font-size: 30px;
}

.header-text span {
  color: $font-color;
}

.header-space {
  min-height: 20px;
  background: $background-nav;
}

.carousel-text {
  min-height: 10vh;
  background-color: $background-nav;
  color: $font-color;
  font-size: 28px;
  text-align: center;
  line-height: 10vh;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.carousel-text, .header-text, .nav-logo, .section-text-tittle {
  animation-name: text;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes text {
  0% {
    letter-spacing: 1.2em;
  }
  100% {
    letter-spacing: 0.5em;
  }
}

@keyframes header-img {
  0% {
    background-position: -300px -50px;
  }
  100% {
    background-position: left -50px;
  }
}

.carousel-wrapper {
  height: 300px;
  background-color: $background-nav;
}

.carousel-wrapper img {
  max-height: 200px;
  max-width: 200px;
  padding: 0 10px;
}

.carousel-scroll {
  width: 50px;
  height: 50px;
  border-bottom: solid 8px #ddd;
  border-right: solid 8px #ddd;
  transform: rotate(45deg);
  position: absolute;
  bottom: 12vh;
  animation: scroll 2000ms infinite;
}

.carousel-scroll:hover {
  cursor: pointer;
}

@keyframes scroll {
  0% {
    bottom: 18vh;
    left: 50%;
  }
  100% {
    bottom: 12vh;
    left: 50%;
  }
}

.carousel .carousel-status {
  display: none;
}

.main-img {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url('../img/mountains.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .main-text-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    padding-bottom: 25px;
  }

  p {
    color: white;
    text-transform: uppercase;
  }

  .main-text {
    font-size: 30px;
    text-align: center;
    padding: 30px 30px;
    letter-spacing: 2px;
  }
}

.section-about {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-nav;
  color: $font-color;
  text-align: center;

  .section-text {
    padding-bottom: 80px;

    .section-text-tittle {
      padding-bottom: 30px;
      text-transform: uppercase;
      font-size: 30px;
    }

    p + p {
      font-size: 20px;
      color: white;
      letter-spacing: 2px;
      padding: 3px 10px;
    }
  }

  .section-wrapper {
    width: 100%;
    padding: 60px 0;
    display: flex;
    flex-direction: row;
    color: black;
    background-color: $font-color;

    .section-item {
      display: flex;
      width: 30vw;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 100px;
        padding-bottom: 20px;
      }

      h3 {
        padding-top: 50px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;
      }
    }
  }
}

.dropdown {
  position: absolute;
  top: 80px;
  width: 110px;
  transform: translateX(25%);
  background-color: #20232A;
  border: solid 1px #555;
  padding: 10px;

  .nav-click {
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
  }
}

.dropdown:hover {
  .nav-click {
    color: $font-color;
  }
}

.dropdown-link {
  text-decoration: none;
}

Footer {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: $background-nav;
  color: #fff;
  padding: 5px 10px;
}

.contact {
  max-height: calc(100vh - 50px);
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #20232A;
  background-image: url('../img/keyboard.jpg');
  background-size: cover;
}

.contact-left, .contact-right {
  width: 25%;
  height: 100vh;
}

.contact-main {
  margin-top: 50px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  width: 50%;
  background: rgba(242, 136, 12, 0.7);

  h1 {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    text-transform: uppercase;
    letter-spacing: 1.7px;
  }

  form {
    width: 600px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    label {
      padding: 10px 30px;
      font-size: 1.2rem;
      letter-spacing: 1.5px;
      font-weight: bold;

      input, textarea {
        margin-top: 10px;
        padding: 3px 10px;
        display: block;
        text-align: left;
        width: 100%;
        border: none;
        border-bottom: 2px solid $background-nav;
        background-color: #fff;
      }

      input {
        height: 35px;
        font-size: 20px;
        color: black;
      }

      textarea {
        height: 100px;
        font-size: 20px;
        color: black;
      }

      p {
        color: brown;
        font-size: 15px;
      }
    }
  }
}

.btn-send {
  margin-top: 50px;
  width: 150px;
  height: 50px;
  align-self: center;
  background-color: $background-nav;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2em;
  border-radius: 20px;
  box-shadow: 2px -3px rgba(5, 5, 5, 0.50);
}

.btn-send:hover {
  cursor: pointer;
  background: #000000;
}

.nav-main-menu a.active {
  color: $font-color;
}

.contact-footer {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
}

.nav-burger-menus {
  display: none;

  .nav-click {
    display: none;
  }
}

@media (max-width: 1200px) {
  .contact-main {
    form {
      width: 500px;

      label {
        input, textarea {
          width: 90%;
        }

      }
    }
  }
}

@media (max-width: 1024px) {
  .header-img {
    height: 25vh;
    background-size: 120%;
    top: 100px;
  }

  nav {
    height: 80px;
    position: relative;
    font-size: 1.2em;
  }

  .nav-logo {
    letter-spacing: 10px;
    font-size: 1.8rem;
    font-weight: lighter;
    text-align: center;
  }
  .carousel-scroll {
    display: none;
  }
  .carousel-wrapper {
    height: 450px;
    background-color: $background-nav;
  }

  .header-space {
    display: none;
  }

  .section-about {
    padding-top: 0;
    min-height: 150px;

    p {
      font-size: 25px;
      letter-spacing: 3px;
      margin: 5px 20px;
      line-height: 1.8em;
    }
  }
  .header-text, .carousel-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  .section-wrapper {
    padding: 40px 0;

    .section-item {
      display: flex;
      width: 25vw;

      img {
        height: 50px;
        padding-bottom: 20px;
      }

      h3 {
        padding: 20px 50px;
      }
    }
  }

  .contact-wrapper {
    height: 70vh;

    form {
      height: 250px;

      button.btn-send {
        margin-top: 10px;
        padding: 20px 10px;
        height: 150px;
        width: 250px;
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 690px) {
  .header-img {
    height: 22vh;
    background-size: 150%;
    top: 20px
  }
  nav {
    height: 60px;
    position: relative;
  }
  .nav-burger {
    display: inline-block;
    cursor: pointer;
    position: fixed;
    top: 12px;
    right: 30px;

    div {
      width: 50px;
      height: 5px;
      background-color: $font-color;
      margin: 6px 0;
      transition: 0.4s;
    }
  }
  .nav-logo {
    letter-spacing: 10px;
    font-size: 1.2rem;
    font-weight: lighter;
    text-align: center;
  }

  .section-about {
    padding-top: 300px;
    height: 150px;

    .section-text {
      .section-text-tittle {
        font-size: 18px;
      }
    }

    .section-text p + p {
      font-size: 13px;
      letter-spacing: 3px;
      margin: 5px 20px;
      line-height: 1.5em;
    }
  }
  .header-text, .carousel-text {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 22px;
    padding-top: 15px;
    height: 30px;

    p {
      padding: 0 10px;
      font-size: 0.5em;
    }
  }

  .carousel-text {
    min-height: 80px;
  }

  .section-wrapper {
    .section-item {
      h3 {
        font-size: 13px;
      }
    }
  }

  .main-img {
    height: 80vh;
  }
  .main-text-wrapper {
    margin-top: 250px;

    .main-text {
      font-size: 15px;
    }
  }
  .nav-burger-menus {
    display: block;

    .nav-click {
      display: block;
    }
  }

  footer {
    height: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .contact-wrapper {

    .contact {
      padding-top: 0;
      height: 70vh;
    }

    .contact-main {
      width: 500px;
      height: 50vh;
    }

    form {
      height: 250px;

      button.btn-send {
        margin-top: 10px;
        padding: 20px 10px;
        height: 150px;
        width: 250px;
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 420px) {

  .header-img {
    height: 300px;
    background-size: 150%;
    background-attachment: scroll;
  }

  nav {
    height: 60px;
    position: relative;

    .nav-logo {
      display: none;
    }
  }

  .section-about {
    padding-top: 900px;
    height: 150px;

    .section-wrapper {
      flex-direction: column;
      align-items: center;
    }

    .section-text {
      .section-text-tittle {
        font-size: 16px;
      }
    }

    .section-text p + p {
      font-size: 13px;
      letter-spacing: 3px;
      margin: 5px 20px;
      line-height: 1.5em;
    }
  }

  .section-about .section-wrapper .section-item img {
    margin-top: 40px;
    padding-bottom: 5px;
  }
  .header-text, .carousel-text {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 22px;
    padding: 20px 10px;
    height: 50px;

    p {
      padding: 20px 20px;
      font-size: 0.35em;
    }
  }

  .carousel-text {
    min-height: 60px;
  }
  .main-img {
    height: 900px;
  }
  .main-text-wrapper {
    margin-top: 590px;

    .main-text {
      font-size: 15px;
    }
  }

  .contact-wrapper {

    .contact {
      width: 100%;
      background-image: none;
      max-height: calc(100vh - 40px);

      height: 100vh;
      z-index: -1;

      .contact-main {
        margin-top: 0;
        height: calc(100vh - 40px);
        padding-bottom: 50px;

        h1 {
          margin-top: 10px;
          margin-bottom: 10px;
          text-transform: uppercase;
          letter-spacing: 1.1px;
          font-size: 24px;
        }

        form {
          width: 360px;

          label {
            padding: 5px 20px;
            font-size: 1rem;
            letter-spacing: 1px;

            p {
              color: brown;
              font-size: 14px;
            }
          }

          button.btn-send {
            margin-top: 40px;
            margin-bottom: 150px;
            padding: 10px 5px;
            width: 120px;
            height: 40px;
            text-transform: uppercase;
            font-size: 20px;
            line-height: 20px;
            border-radius: 20px;
            background: #000;
          }

          .btn-send:hover {
            cursor: pointer;
            background: #000000;
          }
        }
      }
    }
  }

  .contact-footer {
    height: 40px;
    margin-top: 50px;
    margin-bottom: 0;
  }
}

